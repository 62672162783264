export default {
  namespaced: true,
  state: {
    showSwitchPermissionPopup: false,
    userPermissions: [] as UserPermission[],
    userInformation: {} as UserInformation,
  },
  mutations: {
    setSelectedPermission(state: UserInformationState, selectedPermission: SelectedPermission) {
      state.userInformation = { ...state.userInformation, ...selectedPermission };
    },
    setUserToken(state: UserInformationState, token: string) {
      state.userInformation.tokenRefreshTime = new Date();
      localStorage.setItem('token', token);
    },
    setUserPermissions(state: UserInformationState, permissions: Record<string, unknown>[]) {
      state.userPermissions = permissions.map(permission => ({
        brandId: permission.brand as string,
        brandHashId: permission.brandHashId as string,
        countryCode: permission.country as string,
        permission: permission.permission as string,
      }));
    },
    setUserInformation(state: UserInformationState, userInformation: Record<string, string>) {
      const { token, ...restOfUserInformation } = userInformation;
      localStorage.setItem('token', token);
      state.userInformation = { ...state.userInformation, ...restOfUserInformation, tokenRefreshTime: new Date() };
    },
    setShowSwitchPermissionPopup(state: UserInformationState, showSwitchPermissionPopup: boolean) {
      state.showSwitchPermissionPopup = showSwitchPermissionPopup;
    },
  },
  getters: {
    getSelectedPermission(state: UserInformationState): SelectedPermission {
      const { selectedBrandId, selectedCountryCode } = state.userInformation;
      return { selectedBrandId, selectedCountryCode };
    },
    getApiPayload(state: UserInformationState): ApiPayload {
      return {
        email: state.userInformation.email,
        permission: state.userInformation.permission,
        brandId: state.userInformation.selectedBrandId,
        countryCode: state.userInformation.selectedCountryCode,
        brandHashId: state.userInformation.brandHashId,
      };
    },
    getUserInformation(state: UserInformationState) {
      const token = localStorage.getItem('token');
      return { token, ...state.userInformation };
    },
  },
};
