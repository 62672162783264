export default {
  namespaced: true,
  state: {
    showQaLoginPopup: false,
  },
  mutations: {
    setShowQaPopupState(
      state: ShowQaLoginPopupState,
      payload: SetQaLoginPopupPayload,
    ) {
      state.showQaLoginPopup = payload.showQaLoginPopup;
    },
  },
  getters: {
    getShowQaLoginPopupState(state: ShowQaLoginPopupState): boolean {
      return state.showQaLoginPopup;
    },
  },
};
