"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const element_plus_1 = require("element-plus");
const vue_1 = require("vue");
exports.default = (0, vue_1.defineComponent)({
  name: 'DropdownField',
  components: {
    ElOption: element_plus_1.ElOption,
    ElSelect: element_plus_1.ElSelect
  },
  props: {
    options: {
      type: Array,
      default: () => [],
      required: true
    },
    modelValue: {
      type: String,
      default: ''
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      localOptions: [],
      refresh: true,
      value: ''
    };
  },
  computed: {
    loadedOptions() {
      const {
        options
      } = this;
      return options;
    }
  },
  watch: {
    loadedOptions: {
      deep: true,
      immediate: true,
      handler(newValue) {
        this.localOptions = newValue;
        this.refresh = !this.refresh;
      }
    },
    modelValue: {
      immediate: true,
      handler(newValue) {
        this.value = newValue;
      }
    }
  },
  methods: {
    handleChange() {
      this.$emit('update:modelValue', this.value);
    }
  }
});