import { createStore } from 'vuex';

import PageLoad from './modules/PageLoad';
import QaLogin from './modules/QaLogin';
import UserInformation from './modules/UserInformation';

const store = createStore({
  modules: {
    UserInformation,
    PageLoad,
    QaLogin,
  },
});

export default store;
