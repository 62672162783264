"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.render = void 0;
const vue_1 = require("vue");
function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElOption = (0, vue_1.resolveComponent)("ElOption");
  const _component_ElSelect = (0, vue_1.resolveComponent)("ElSelect");
  return (0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("div", null, [((0, vue_1.openBlock)(), (0, vue_1.createBlock)(_component_ElSelect, {
    key: _ctx.refresh,
    modelValue: _ctx.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.value = $event),
    filterable: "",
    placeholder: "Select",
    onChange: _ctx.handleChange
  }, {
    default: (0, vue_1.withCtx)(() => [((0, vue_1.openBlock)(true), (0, vue_1.createElementBlock)(vue_1.Fragment, null, (0, vue_1.renderList)(_ctx.localOptions, item => {
      return (0, vue_1.openBlock)(), (0, vue_1.createBlock)(_component_ElOption, {
        key: item.value,
        label: item.label,
        value: item.value
      }, null, 8, ["label", "value"]);
    }), 128))]),
    _: 1
  }, 8, ["modelValue", "onChange"]))]);
}
exports.render = render;