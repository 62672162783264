"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
const element_plus_1 = require("element-plus");
const vue_1 = require("vue");
const vuex_1 = require("vuex");
const ApiHandler_1 = require("@/ApiHandler");
const constants_1 = require("../../../shared/constants");
const constants_2 = require("../constants");
exports.default = (0, vue_1.defineComponent)({
  name: 'FileUpload',
  components: {
    ElUpload: element_plus_1.ElUpload,
    ElDialog: element_plus_1.ElDialog
  },
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    disableStatus: {
      type: Boolean,
      default: false
    },
    acceptFiletype: {
      type: String,
      default: constants_1.fileExtension.UPLOAD_FILE
    },
    fieldId: {
      type: String,
      default: ''
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      imageVisible: false,
      fileList: [],
      loading: false
    };
  },
  computed: Object.assign({
    imageUrl() {
      const {
        fileList
      } = this;
      if (fileList.length > 0) {
        return fileList[0].url || '';
      }
      return '';
    },
    previewStatus() {
      const {
        fileList
      } = this;
      if (fileList.length > 0) {
        return 'enable';
      }
      return 'disable';
    }
  }, (0, vuex_1.mapState)('UserInformation', ['userInformation'])),
  watch: {
    modelValue: {
      immediate: true,
      handler(newValue) {
        if (!newValue) {
          this.fileList = [];
        } else {
          this.fileList = [{
            name: newValue.split('/').pop().split('%2F').pop(),
            url: newValue
          }];
        }
      }
    }
  },
  methods: {
    handlePreview() {
      var _a;
      if (this.fileList.length === 0 || !this.fileList[0].url) {
        return;
      }
      const theFileExtension = `.${(_a = this.fileList[0].name.split('.').pop()) === null || _a === void 0 ? void 0 : _a.toLocaleLowerCase()}`;
      if (theFileExtension === constants_1.fileExtension.PDF_FILE) {
        window.open(this.fileList[0].url, '_blank');
      } else if (constants_1.fileExtension.IMAGE_FILE.indexOf(theFileExtension) >= 0) {
        this.imageVisible = true;
      }
    },
    handleExceed(files) {
      const file = files[0];
      file.uid = (0, element_plus_1.genFileId)();
      if (this.checkMaxFileSize(file)) {
        return;
      }
      this.handleUpload(file);
    },
    handleChange(file) {
      const newFile = file.raw;
      if (this.checkMaxFileSize(newFile)) {
        return;
      }
      this.handleUpload(newFile);
    },
    checkMaxFileSize(file) {
      let exceedMaxSize = false;
      if (file.name.toLocaleLowerCase().endsWith(constants_1.fileExtension.PDF_FILE)) {
        if (file.size > constants_1.maxFileSize.PDF_FILE) {
          exceedMaxSize = true;
        }
      } else if (file.size > constants_1.maxFileSize.IMAGE_FILE) {
        exceedMaxSize = true;
      }
      if (exceedMaxSize) {
        element_plus_1.ElMessageBox.alert(constants_1.ERROR_EXCEED_FILE_MAXSIZE, 'Warning', {
          confirmButtonText: 'OK',
          type: constants_2.messageType.WARNING
        });
      }
      return exceedMaxSize;
    },
    handleUpload(file) {
      return __awaiter(this, void 0, void 0, function* () {
        this.loading = true;
        const uploadData = new FormData();
        uploadData.append(constants_1.UPLOAD_FIELD_NAME, file);
        uploadData.append('email', this.userInformation.email);
        uploadData.append('permission', this.userInformation.permission);
        uploadData.append('brandId', this.userInformation.selectedBrandId);
        uploadData.append('countryCode', this.userInformation.selectedCountryCode);
        uploadData.append('brandHashId', this.userInformation.brandHashId);
        uploadData.append('fieldId', this.fieldId);
        const previousFileName = this.fileList.length > 0 ? this.fileList[0].name : '';
        // back end should delete previousFileName from Firebase Storage
        uploadData.append('previousFileName', previousFileName);
        const apiHandler = new ApiHandler_1.ApiHandler();
        const result = yield apiHandler.post('upload-file', uploadData, undefined, {
          'Content-type': constants_1.HTTP_HEADERS_CONTENT_TYPE.FORM_DATA
        });
        if ((result === null || result === void 0 ? void 0 : result.status) === constants_1.RESTAPI_STATUS_CODE.OK) {
          const {
            data
          } = result;
          this.fileList = [{
            name: data.fileName,
            url: data.fileUrl
          }];
          this.$emit('update:modelValue', data.fileUrl);
        }
        this.loading = false;
      });
    }
  }
});