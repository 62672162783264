/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ApiHandler } from '@/ApiHandler';
import { LOCALHOST, QA_HOST } from '@/constants';
import Store from '@/store/index';

import {
  CMS_API_FAILURE,
  CMS_API_BASE_URL,
  HTTP_HEADERS_CONTENT_TYPE,
  CMS_API_PATH,
} from '../../../shared/constants';

export default class Authentication {
  static checkProductionAuthentication() {
    if (!this.getToken() && this.checkDomain(process.env.VUE_APP_CMS_BASE_URL!)) {
      this.redirectToCMSLogin();
    }
  }

  static async checkDevelopmentAuthentication(
    email?: string,
    password?: string,
  ) {
    // Before user login
    if (
      !this.getToken() &&
      (this.checkDomain(LOCALHOST) || this.checkDomain(QA_HOST)) &&
      (!email || !password)
    ) {
      Store.commit('QaLogin/setShowQaPopupState', { showQaLoginPopup: true });
      return;
    }

    // if it is localhost, should call CMS Login API to get user information
    // TODO: on QA web site https://web2-dev-gcp.modiface.com, user token is not retrieved.
    //       need the decision how to get user token on QA server, redirect or call CMS Login API
    if (
      this.getToken() &&
      this.checkDomain(process.env.VUE_APP_CMS_BASE_URL!)
    ) {
      const payload = {
        email: localStorage.getItem('userId'),
        token: localStorage.getItem('token'),
        permission: localStorage.getItem('userType'),
        selectedBrandId: localStorage.getItem('primaryBrandId'),
        selectedCountryCode: localStorage.getItem('countryCode'),
      };

      Store.commit('UserInformation/setUserInformation', payload);
      return;
    }

    if (this.checkDomain(LOCALHOST) || this.checkDomain(QA_HOST)) {
      if (!this.getToken()) {
        await this.getUserInformation(email || '', password || '');
        return;
      }
      const data = {
        email: localStorage.getItem('userId'),
        token: localStorage.getItem('token'),
        permission: localStorage.getItem('userType'),
        selectedBrandId: localStorage.getItem('primaryBrandId'),
        selectedCountryCode: localStorage.getItem('countryCode'),
      };
      Store.commit('UserInformation/setUserInformation', data);
    }
  }

  static async getUserInformation(email: string, password: string) {
    const searchParams = new URLSearchParams();
    searchParams.append('email', email);
    searchParams.append('password', password);

    let errorMessage = '';
    try {
      const apiHandler = new ApiHandler();
      const response = await apiHandler.post(
        CMS_API_PATH.USER_LOGIN,
        searchParams,
        CMS_API_BASE_URL,
        { 'Content-type': HTTP_HEADERS_CONTENT_TYPE.FORM },
      );

      errorMessage = response?.data;

      const data = response!.data!.data;
      data.permission = data.userType;
      this.saveLocalStorageData(data);
      localStorage.setItem('userId', email);
      const payload = {
        email,
        token: data.token,
        permission: data.userType,
        selectedBrandId: data.primaryBrandId,
        selectedCountryCode: data.countryCode,
      };
      Store.commit('UserInformation/setUserInformation', payload);
    } catch (error) {
      alert(`${CMS_API_FAILURE}: ${errorMessage}`);
      throw error;
    }
  }

  static saveLocalStorageData(data: localStroageData) {
    for (const key in data) {
      localStorage.setItem(key, data[key] + '');
    }
  }

  static redirectToCMSLogin() {
    const redirectUrl = window.location.pathname.replace('/', '');
    const cmsLoginUrl = `${process.env.VUE_APP_CMS_BASE_URL}v3/shadematchingtool-web/login.html?redirect=${redirectUrl}#/login-view`;
    window.location.href = cmsLoginUrl;
  }

  static getToken(): string {
    const token = localStorage.token;
    if (token && token !== 'null') {
      return token;
    }
    return '';
  }

  static checkDomain(url: string): boolean {
    const href = window.location.href;
    return href.startsWith(url);
  }
}
