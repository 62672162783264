"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.render = void 0;
const vue_1 = require("vue");
function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BaseHeader = (0, vue_1.resolveComponent)("BaseHeader");
  const _component_ElHeader = (0, vue_1.resolveComponent)("ElHeader");
  const _component_router_view = (0, vue_1.resolveComponent)("router-view");
  const _component_ElContainer = (0, vue_1.resolveComponent)("ElContainer");
  return (0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("div", null, [(0, vue_1.createVNode)(_component_ElContainer, {
    class: "h-screen"
  }, {
    default: (0, vue_1.withCtx)(() => [(0, vue_1.createVNode)(_component_ElHeader, {
      height: "5rem",
      style: {
        "padding": "0"
      }
    }, {
      default: (0, vue_1.withCtx)(() => [(0, vue_1.createVNode)(_component_BaseHeader)]),
      _: 1
    }), (0, vue_1.createVNode)(_component_router_view, {
      class: "h-full"
    })]),
    _: 1
  })]);
}
exports.render = render;