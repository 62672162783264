"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.render = void 0;
const vue_1 = require("vue");
const _withScopeId = n => ((0, vue_1.pushScopeId)("data-v-5dfec64c"), n = n(), (0, vue_1.popScopeId)(), n);
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  key: 1
};
const _hoisted_3 = {
  class: "field-title"
};
const _hoisted_4 = {
  class: "field-description"
};
function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElCheckbox = (0, vue_1.resolveComponent)("ElCheckbox");
  const _component_FileUpload = (0, vue_1.resolveComponent)("FileUpload");
  const _component_ElInput = (0, vue_1.resolveComponent)("ElInput");
  const _component_ElTableColumn = (0, vue_1.resolveComponent)("ElTableColumn");
  const _component_ElTable = (0, vue_1.resolveComponent)("ElTable");
  return _ctx.localData.length ? ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("div", _hoisted_1, [(0, vue_1.createVNode)(_component_ElTable, {
    data: _ctx.localData,
    "header-cell-style": {
      fontSize: '0.75rem',
      color: '#555'
    },
    "cell-class-name": _ctx.cellClassName
  }, {
    default: (0, vue_1.withCtx)(() => [((0, vue_1.openBlock)(true), (0, vue_1.createElementBlock)(vue_1.Fragment, null, (0, vue_1.renderList)(_ctx.tableColumns, (column, index) => {
      return (0, vue_1.openBlock)(), (0, vue_1.createBlock)(_component_ElTableColumn, {
        key: column.key,
        fixed: index === 0,
        label: column.value,
        width: "340"
      }, (0, vue_1.createSlots)({
        _: 2
      }, [index === 0 ? {
        name: "default",
        fn: (0, vue_1.withCtx)(scope => [(0, vue_1.createElementVNode)("div", null, [scope.row.type === `${_ctx.typeCheckboxTranslation}` || scope.row.type === `${_ctx.typeImageFileTranslation}` || scope.row.type === `${_ctx.typePDFFileTranslation}` ? ((0, vue_1.openBlock)(), (0, vue_1.createBlock)(_component_ElCheckbox, {
          key: 0,
          modelValue: scope.row.enabled,
          "onUpdate:modelValue": $event => scope.row.enabled = $event,
          label: scope.row.title,
          onChange: _cache[0] || (_cache[0] = $event => _ctx.$emit('data-updated'))
        }, null, 8, ["modelValue", "onUpdate:modelValue", "label"])) : ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("div", _hoisted_2, [(0, vue_1.createElementVNode)("div", _hoisted_3, (0, vue_1.toDisplayString)(scope.row.title), 1), (0, vue_1.createElementVNode)("div", _hoisted_4, (0, vue_1.toDisplayString)(scope.row.description), 1)]))])]),
        key: "0"
      } : {
        name: "default",
        fn: (0, vue_1.withCtx)(scope => [scope.row.type === `${_ctx.typeImageFileTranslation}` || scope.row.type === `${_ctx.typePDFFileTranslation}` ? ((0, vue_1.openBlock)(), (0, vue_1.createBlock)(_component_FileUpload, {
          key: 0,
          modelValue: scope.row.value[column.key],
          "onUpdate:modelValue": $event => scope.row.value[column.key] = $event,
          "disable-status": !column.enabled || !scope.row.enabled,
          "accept-filetype": _ctx.getAcceptedFileType(scope.row.type),
          onChange: _cache[1] || (_cache[1] = $event => _ctx.$emit('data-updated'))
        }, null, 8, ["modelValue", "onUpdate:modelValue", "disable-status", "accept-filetype"])) : ((0, vue_1.openBlock)(), (0, vue_1.createBlock)(_component_ElInput, {
          key: 1,
          modelValue: scope.row.value[column.key],
          "onUpdate:modelValue": $event => scope.row.value[column.key] = $event,
          disabled: !_ctx.enabledColumns[column.key] || !scope.row.enabled,
          class: (0, vue_1.normalizeClass)(["field-value", {
            'invalid-input': !_ctx.checkIsValidCell(scope.row.id, column.key)
          }]),
          onChange: _cache[2] || (_cache[2] = $event => _ctx.$emit('data-updated'))
        }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled", "class"]))]),
        key: "1"
      }]), 1032, ["fixed", "label"]);
    }), 128))]),
    _: 1
  }, 8, ["data", "header-cell-style", "cell-class-name"])])) : (0, vue_1.createCommentVNode)("", true);
}
exports.render = render;