export const invalidDataPopupHeaderText = 'Progress Not Saved';
export const invalidDataPopupBodyText =
  'Your progress has not been saved because of errors in certain fields. Please fill them out correctly before saving progress again.';
export const SWITCH_PERMISSIONS = 'Switch Permissions';
export const WARNING_NO_PERMISSIONS =
  'You don\'t have any permission to edit configuration data, please login again using another account.';

export const enum messageType {
  SUCCESS = 'success',
  WARNING = 'warning',
  INFO = 'info',
  ERROR = 'error',
}

export const enum popupType {
  MISSING_FIELDS = 'missingFields',
  PUSH_LIVE = 'pushLive',
  TOKEN_EXPIRED = 'tokenExpired'
}

export const LOCALHOST = 'http://localhost';
// TODO: need the decision how to get user token on QA server, redirect or call CMS Login API
//       should delet qahost const
export const QA_HOST = 'https://web2-dev-gcp.modiface.com';
export const TOKEN_REFRESH_TIME_LIMIT = 60000; // 1 minute
