"use strict";

require("core-js/modules/es.array.push.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
const vue_1 = require("vue");
exports.default = (0, vue_1.defineComponent)({
  name: 'HomeView',
  data() {
    return {
      configSteps: [{
        name: 'ModiFace SDK Integration Guide',
        link: 'https://loreal-webconsultation.modiface.com/teleconsult/3/_docs/installation.html#barebone-sample-integration-using-kerastase-fra-as-an-example'
      }, {
        name: 'JSON Template',
        link: 'https://loreal-webconsultation.modiface.com/teleconsult/3/_docs/API/appendix.html#appendix-e-sample-addframe-json-template'
      }, {
        name: 'ModiFace Product CMS',
        link: 'https://shadematching.modiface.com/shadematchingtool-web/login.html'
      }, {
        name: 'ModiFace Settings CMS',
        link: '/settings-cms'
      }, {
        name: 'BA App Download Link',
        link: 'https://testlinks.modiface.com/test_apps/BA_App_V3/index.html'
      }, {
        name: 'Booxi US',
        link: 'https://app.booxi.com/'
      }, {
        name: 'Booxi EU',
        link: 'https://app.booxi.eu/'
      }, {
        name: 'SendGrid',
        link: 'https://app.sendgrid.com/'
      }]
    };
  },
  methods: {
    redirectToLink(link) {
      window.open(link, '_blank');
    },
    routeToCmsSettings() {
      this.$router.push({
        path: '/settings-cms'
      });
    }
  }
});