"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.render = void 0;
const vue_1 = require("vue");
function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElCheckbox = (0, vue_1.resolveComponent)("ElCheckbox");
  return (0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("div", null, [(0, vue_1.createVNode)(_component_ElCheckbox, {
    modelValue: _ctx.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.value = $event),
    class: "checkbox",
    onChange: _ctx.handleChange
  }, null, 8, ["modelValue", "onChange"])]);
}
exports.render = render;