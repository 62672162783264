import "core-js/modules/es.array.push.js";
import Store from '@/store/index';
import { SWITCH_PERMISSIONS } from '../constants';
export default {
  name: 'BaseHeader',
  setup() {
    return {
      switchPermissions: SWITCH_PERMISSIONS
    };
  },
  computed: {
    isSettingsCmsRoute() {
      return this.$route.name === 'settingsCms';
    }
  },
  methods: {
    routeToHome() {
      this.$router.push({
        path: '/'
      });
    },
    showSwitchPermissions() {
      Store.commit('UserInformation/setShowSwitchPermissionPopup', true);
    },
    logout() {
      Store.commit('UserInformation/setUserToken', '');
      localStorage.clear();
      this.$router.push({
        path: '/'
      });
    }
  }
};