"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const element_plus_1 = require("element-plus");
const vue_1 = require("vue");
const icons_vue_1 = require("@element-plus/icons-vue");
const constants_1 = require("../constants");
exports.default = (0, vue_1.defineComponent)({
  name: 'SwitchPermissions',
  components: {
    ElDialog: element_plus_1.ElDialog,
    ElInput: element_plus_1.ElInput,
    ElScrollbar: element_plus_1.ElScrollbar
  },
  props: {
    show: {
      type: Boolean,
      default: () => false
    },
    selectedPermission: {
      type: Object,
      default: () => ({})
    },
    permissions: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['switch', 'cancel'],
  setup() {
    return {
      Search: icons_vue_1.Search,
      scrollbarHeight: 365
    };
  },
  data() {
    return {
      headerText: constants_1.SWITCH_PERMISSIONS,
      showDialog: false,
      searchKey: '',
      selectedIndex: 0,
      initialSelectedIndex: 0
    };
  },
  watch: {
    show: {
      immediate: true,
      handler(newValue) {
        this.showDialog = newValue;
      }
    },
    selectedPermission: {
      immediate: true,
      handler(newValue) {
        if (newValue.selectedBrandId && newValue.selectedCountryCode) {
          const foundIndex = this.permissions.findIndex(permission => permission.brand === newValue.selectedBrandId && permission.country === newValue.selectedCountryCode);
          if (foundIndex >= 0) {
            this.selectedIndex = foundIndex;
            this.initialSelectedIndex = foundIndex;
          }
        }
      }
    }
  },
  methods: {
    onSearchChange(searchValue) {
      const searchValueLowerCase = searchValue.trim().toLocaleLowerCase();
      if (searchValueLowerCase === '') {
        return;
      }
      const permissionIndex = this.permissions.findIndex(permission => permission.brand.toLocaleLowerCase().includes(searchValueLowerCase) || permission.country.toLocaleLowerCase().includes(searchValueLowerCase));
      if (permissionIndex >= 0) {
        this.selectedIndex = permissionIndex;
        const innerDivRef = this.$refs.innerDivRef;
        if (innerDivRef.clientHeight > this.scrollbarHeight) {
          const maxScrollbarTop = innerDivRef.clientHeight - this.scrollbarHeight + 20;
          const scrollbarTopValue = Math.round(permissionIndex / this.permissions.length * maxScrollbarTop);
          this.$refs.scrollbarRef.setScrollTop(scrollbarTopValue);
        }
      }
    },
    onSelectItem(index) {
      this.selectedIndex = index;
    },
    handleSwitch() {
      const selectedItem = this.permissions[this.selectedIndex];
      const selectedPayload = {
        permission: selectedItem.permission,
        selectedBrandId: selectedItem.brand,
        selectedCountryCode: selectedItem.country,
        brandHashId: selectedItem.brandHashId
      };
      this.$emit('switch', selectedPayload);
    },
    handleClose() {
      this.selectedIndex = this.initialSelectedIndex;
      this.$emit('cancel');
    }
  }
});