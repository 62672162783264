"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const element_plus_1 = require("element-plus");
const vue_1 = require("vue");
exports.default = (0, vue_1.defineComponent)({
  name: 'CheckboxField',
  components: {
    ElCheckbox: element_plus_1.ElCheckbox
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      value: false
    };
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(newValue) {
        this.value = newValue;
      }
    }
  },
  methods: {
    handleChange() {
      this.$emit('update:modelValue', this.value);
    }
  }
});