import type { Commit } from 'vuex';

export default {
  namespaced: true,
  state: {
    loading: false,
  },
  mutations: {
    setPageLoadingState(state: PageLoadState, payload: SetPageLoadPayload) {
      state.loading = payload.loading;
    },
  },
  actions: {
    setPageLoadingState(
      { commit }: { commit: Commit },
      payload: SetPageLoadPayload,
    ): void {
      commit('setPageLoadingState', payload);
    },
  },
  getters: {
    getPageLoadingState(state: PageLoadState): boolean {
      return state.loading;
    },
  },
};
