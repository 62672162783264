"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.render = void 0;
const vue_1 = require("vue");
const _withScopeId = n => ((0, vue_1.pushScopeId)("data-v-b8a8f2c8"), n = n(), (0, vue_1.popScopeId)(), n);
const _hoisted_1 = {
  class: "flex items-center"
};
function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElSlider = (0, vue_1.resolveComponent)("ElSlider");
  return (0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("div", _hoisted_1, [(0, vue_1.createVNode)(_component_ElSlider, {
    modelValue: _ctx.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.value = $event),
    class: "slider pr-5",
    "show-tooltip": false,
    min: _ctx.min,
    max: _ctx.max,
    onChange: _ctx.handleChange
  }, null, 8, ["modelValue", "min", "max", "onChange"]), (0, vue_1.createElementVNode)("p", null, (0, vue_1.toDisplayString)(_ctx.value) + "%", 1)]);
}
exports.render = render;