"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.render = void 0;
const vue_1 = require("vue");
const _withScopeId = n => ((0, vue_1.pushScopeId)("data-v-c74222b6"), n = n(), (0, vue_1.popScopeId)(), n);
const _hoisted_1 = {
  class: "flex h-screen"
};
const _hoisted_2 = {
  class: "m-auto mt-[100px] grid grid-cols-2"
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = ["onClick"];
function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("div", null, [(0, vue_1.createElementVNode)("div", _hoisted_1, [(0, vue_1.createElementVNode)("div", _hoisted_2, [((0, vue_1.openBlock)(true), (0, vue_1.createElementBlock)(vue_1.Fragment, null, (0, vue_1.renderList)(_ctx.configSteps.slice(0, 3), configStep => {
    return (0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("button", {
      key: configStep.name,
      onClick: $event => _ctx.redirectToLink(configStep.link)
    }, (0, vue_1.toDisplayString)(configStep.name), 9, _hoisted_3);
  }), 128)), (0, vue_1.createElementVNode)("button", {
    onClick: _cache[0] || (_cache[0] = $event => _ctx.routeToCmsSettings())
  }, (0, vue_1.toDisplayString)(_ctx.configSteps[3].name), 1), ((0, vue_1.openBlock)(true), (0, vue_1.createElementBlock)(vue_1.Fragment, null, (0, vue_1.renderList)(_ctx.configSteps.slice(4), configStep => {
    return (0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("button", {
      key: configStep.name,
      onClick: $event => _ctx.redirectToLink(configStep.link)
    }, (0, vue_1.toDisplayString)(configStep.name), 9, _hoisted_4);
  }), 128))])])]);
}
exports.render = render;