import { ElDialog } from 'element-plus/es';
import 'element-plus/es/components/dialog/style/css';
import { defineComponent } from 'vue';
import { invalidDataPopupHeaderText, invalidDataPopupBodyText } from '.././constants';
export default defineComponent({
  name: 'InvalidDataPopup',
  components: {
    ElDialog
  },
  props: {
    popupOpen: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => ({
    headerText: invalidDataPopupHeaderText,
    bodyText: invalidDataPopupBodyText
  }),
  computed: {
    popupOpenModel: {
      get() {
        return this.popupOpen;
      },
      set(value) {
        this.popupopen = value;
      }
    }
  }
});