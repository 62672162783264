export const enum CREATOR_TYPE {
  CHECKBOX = 'Checkbox',
  CHECKBOX_GROUP = 'Checkbox group',
  CHECKBOX_WITH_TEXT = 'Checkbox with text',
  TRANSLATION = 'Translation',
  CHECKBOX_WITH_TRANSLATION = 'Checkbox with translation',
  DROPDOWN = 'Dropdown',
  IMAGE_FILE = 'Image File',
  PDF_FILE = 'PDF File',
  IMAGE_FILE_WITH_TRANSLATION = 'Image File with translation',
  PDF_FILE_WITH_TRANSLATION = 'PDF File with translation',
  NUMBER = 'Number',
  TEXT = 'Text',
}

export const enum FIELD_TYPE {
  CHECKBOX = 'Checkbox',
  CHECKBOX_GROUP = 'Checkbox group',
  CHECKBOX_WITH_TEXT = 'Checkbox with text',
  TRANSLATION = 'Translation',
  CHECKBOX_WITH_TRANSLATION = 'Checkbox with translation',
  DROPDOWN = 'Dropdown',
  DYNAMIC_DROPDOWN = 'Dynamic Dropdown',
  IMAGE_FILE = 'Image File',
  PDF_FILE = 'PDF File',
  IMAGE_FILE_WITH_TRANSLATION = 'Image File with translation',
  PDF_FILE_WITH_TRANSLATION = 'PDF File with translation',
  NUMBER = 'Number',
  TEXT = 'Text',
}

export const enum ERROR_CODE {
  MISSING_FIELD = 'MISSING_FIELD',
  INVALID_DATA = 'INVALID_DATA',
}

export const enum fileExtension {
  UPLOAD_FILE = '.png,.jpg,.jpeg,.svg,.pdf',
  IMAGE_FILE = '.png,.jpg,.jpeg,.svg',
  PDF_FILE = '.pdf',
}

export const fieldTypeToAcceptedFileType: Record<string, string> = {
  [FIELD_TYPE.IMAGE_FILE]: fileExtension.IMAGE_FILE,
  [FIELD_TYPE.IMAGE_FILE_WITH_TRANSLATION]: fileExtension.IMAGE_FILE,
  [FIELD_TYPE.PDF_FILE]: fileExtension.PDF_FILE,
  [FIELD_TYPE.PDF_FILE_WITH_TRANSLATION]: fileExtension.PDF_FILE,
};

export const enum maxFileSize {
  IMAGE_FILE = 10485760,
  PDF_FILE = 10485760,
}

export const RESTAPI_STATUS_CODE = {
  OK: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  PARTIAL_CONTENT: 206,
  BAD_REQUEST: 400,
  UNAUTHORIZD: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  NOT_ACCEPTABLE: 406,
  CONFLICT: 409,
  INTERNAL_SERVER_ERROR: 500,
};

export const enum HTTP_HEADERS_CONTENT_TYPE {
  JSON = 'application/json',
  FORM = 'application/x-www-form-urlencoded',
  FORM_DATA = 'multipart/form-data',
}

export const enum CMS_API_PATH {
  USER_LOGIN = 'v3.3/user/login',
  USER_ACTIVE = 'v3.3/user/active',
  CHECK_PERMISSION = 'v3.5/teleconsultation/checkPermission',
  BRAND_INFO = 'v3.3/brand/info',
}

export const enum AXIOS_METHOD {
  GET = 'get',
  POST = 'post',
}

export const ENABLE_POSTFIX = '_enabled';
export const TRANSLATE_POSTFIX = '_trans';
export const ROW_HEADER = 'rowHeader';
export const ENABLED_LANGUAGES = 'enabled_languages';
export const TRANSLATION = 'translation';
export const ERROR_EXCEED_FILE_MAXSIZE =
  'The file is too large. The maximum file size is 10M.';
export const ERROR_IMAGE_SIZE_MISMATCH = 'Uploaded Image size not valid';
export const UPLOAD_FIELD_NAME = 'file';
export const CMS_API_FAILURE = 'CMS API failure';
export const CMS_API_BASE_URL = 'https://cms-webservice.modiface.com/api/';
