import { ElLoading } from 'element-plus';
import 'element-plus/dist/index.css';
import { createApp } from 'vue';

import App from '../../web/src/App.vue';

import './index.css';
import router from './router';
import store from './store';

createApp(App).use(ElLoading).use(router).use(store).mount('#app');
