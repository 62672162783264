"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.render = void 0;
const vue_1 = require("vue");
const _withScopeId = n => ((0, vue_1.pushScopeId)("data-v-3feaf1b6"), n = n(), (0, vue_1.popScopeId)(), n);
const _hoisted_1 = ["src"];
function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElUpload = (0, vue_1.resolveComponent)("ElUpload");
  const _component_ElDialog = (0, vue_1.resolveComponent)("ElDialog");
  const _directive_loading = (0, vue_1.resolveDirective)("loading");
  return (0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("div", null, [(0, vue_1.withDirectives)(((0, vue_1.openBlock)(), (0, vue_1.createBlock)(_component_ElUpload, {
    modelValue: _ctx.fileList,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.fileList = $event),
    class: (0, vue_1.normalizeClass)(`backgroud-box-${_ctx.disableStatus}`),
    name: "file",
    action: "#",
    accept: _ctx.acceptFiletype,
    "on-exceed": _ctx.handleExceed,
    "on-change": _ctx.handleChange,
    limit: 1,
    "auto-upload": false,
    "show-file-list": false,
    disabled: _ctx.disableStatus
  }, {
    trigger: (0, vue_1.withCtx)(() => [(0, vue_1.createElementVNode)("div", {
      class: (0, vue_1.normalizeClass)(`button upload-button-${_ctx.disableStatus}`)
    }, "Upload", 2)]),
    default: (0, vue_1.withCtx)(() => [(0, vue_1.createElementVNode)("div", {
      class: (0, vue_1.normalizeClass)(`button review-button-${_ctx.previewStatus}`),
      onClick: _cache[0] || (_cache[0] =
      //@ts-ignore
      (...args) => _ctx.handlePreview && _ctx.handlePreview(...args))
    }, "Preview", 2)]),
    _: 1
  }, 8, ["modelValue", "class", "accept", "on-exceed", "on-change", "disabled"])), [[_directive_loading, _ctx.loading]]), (0, vue_1.createVNode)(_component_ElDialog, {
    modelValue: _ctx.imageVisible,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.imageVisible = $event),
    title: "",
    "align-center": "",
    "append-to-body": true
  }, {
    default: (0, vue_1.withCtx)(() => [(0, vue_1.createElementVNode)("div", {
      class: "main",
      onClick: _cache[2] || (_cache[2] = $event => _ctx.imageVisible = false)
    }, [(0, vue_1.createElementVNode)("img", {
      class: "image",
      src: _ctx.imageUrl
    }, null, 8, _hoisted_1)])]),
    _: 1
  }, 8, ["modelValue"])]);
}
exports.render = render;