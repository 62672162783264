"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const element_plus_1 = require("element-plus");
const vue_1 = require("vue");
exports.default = (0, vue_1.defineComponent)({
  name: 'SliderField',
  components: {
    ElSlider: element_plus_1.ElSlider
  },
  props: {
    range: {
      type: Array,
      default: () => [0, 100]
    },
    modelValue: {
      type: Number,
      default: 0
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      value: 0
    };
  },
  computed: {
    min() {
      return this.range[0];
    },
    max() {
      return this.range[1];
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(newValue) {
        this.value = newValue;
      }
    }
  },
  methods: {
    handleChange() {
      this.$emit('update:modelValue', this.value);
    }
  }
});