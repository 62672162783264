/* eslint-disable @typescript-eslint/no-non-null-assertion */
import axios, { AxiosError, AxiosRequestHeaders } from 'axios';

import Authentication from '@/service/Authentication';

import {
  HTTP_HEADERS_CONTENT_TYPE,
  AXIOS_METHOD,
} from '../../shared/constants';

export class ApiHandler {
  public async request(
    methpd: string = AXIOS_METHOD.GET,
    path: string,
    data: Record<string, string | number | unknown> | object,
    baseUrl: string = process.env.VUE_APP_API_BASE_URL!,
    requestHeaders?: AxiosRequestHeaders,
  ) {
    let headers: AxiosRequestHeaders = {
      'Content-type': HTTP_HEADERS_CONTENT_TYPE.JSON,
    };
    if (baseUrl === process.env.VUE_APP_API_BASE_URL) {
      const token = Authentication.getToken();
      if (data instanceof FormData) {
        (data as FormData).append('token', token);
      } else {
        data = { ...(data as object), token };
      }
    }
    if (requestHeaders) {
      headers = { ...headers, ...requestHeaders };
    }
    try {
      switch (methpd) {
        case AXIOS_METHOD.GET:
          return await axios.get(baseUrl + path, {
            params: data,
            headers,
          });
        case AXIOS_METHOD.POST:
          return await axios.post(baseUrl + path, data, { headers });
      }
    } catch (error) {
      return this.catchError(error as AxiosError);
    }
    return;
  }

  public async post(
    path: string,
    data: Record<string, string | number | unknown> | object,
    baseUrl: string = process.env.VUE_APP_API_BASE_URL!,
    requestHeaders?: AxiosRequestHeaders,
  ) {
    return this.request(AXIOS_METHOD.POST, path, data, baseUrl, requestHeaders);
  }

  public async get(
    path: string,
    data: Record<string, string | number | unknown>,
    baseUrl: string = process.env.VUE_APP_API_BASE_URL!,
    requestHeaders?: AxiosRequestHeaders,
  ) {
    return this.request(AXIOS_METHOD.GET, path, data, baseUrl, requestHeaders);
  }

  private catchError(e: AxiosError) {
    if (e.response?.data) {
      // catch and display backend API errorMessage
      const data = e.response.data as Record<string, unknown>;
      if (data.payload) {
        return e.response;
      }
      if (typeof data === 'object') {
        const errorMessage = (data as Record<string, unknown>)['errorMessage'];
        alert(errorMessage ? `${e.message}: ${errorMessage}` : e.message);
      }
    } else {
      alert(e.message);
    }
    return e.response;
  }
}
